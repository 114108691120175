import React, { useState, useEffect } from 'react'
import { ButtonGroup, Spinner, Button } from 'react-bootstrap';
import { withRouter } from 'react-router';
import queryString from "query-string";
import { getFollowApi } from '../../api/follow';
import BasicLayout from '../../layout/basicLayout/basicLayout'
import "./users.scss"
import ListUser from '../../components/listUser';
import { isEmpty } from 'lodash-es';
import { useDebounce, useDebouncedCallback } from 'use-debounce/lib';


function Users(props) {
    const { setRefreshLogin, location, history } = props;
    const [users, setUsers] = useState(null)
    const params = useUserQuery(location);
    const [typeUser, setTypeUser] = useState(params.type || "follow")
    const [btnLoading, setBtnLoading] = useState(false)

    const onSearch = useDebouncedCallback((value) => {
        setUsers(null)
        history.push({
            search: queryString.stringify({...params, search: value, page: 1})
        })
    }, 333)

    useEffect(() => {
        getFollowApi(queryString.stringify(params))
            .then(response => {
                if (params.page == 1) {                    
                    if (isEmpty(response)) {
                        setUsers([])
                    } else {
                        setUsers(response)
                    }
                } else {
                    if (!response) {
                        setBtnLoading(0)
                    } else {
                        setUsers([...users, ...response])
                        setBtnLoading(false)
                    }
                }
            })
            .catch(() => {
                setUsers([])
            })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])

    const onChangeType = type => {
        setUsers(null)
        if (type === "new") {
            setTypeUser("new")
        } else {
            setTypeUser("follow")
        }
        history.push({
            search: queryString.stringify({type, page: 1, search: ""})
        })
    }

    const moreData = () => {
        setBtnLoading(true)
        const newPage = parseInt(params.page) + 1;
        history.push({
            search: queryString.stringify({...params, page: newPage})
        })
    }

    return (
        <BasicLayout 
            title="Usuarios"
            className="users" 
            setRefreshLogin={setRefreshLogin}>
            <div className="users__title">
                <h2>Usuarios</h2>
                <input
                    type="text"
                    placeholder="Busca un usuario"
                    onChange={e => onSearch(e.target.value)}></input>
            </div>
            <ButtonGroup className="users__options">
                <Button
                    className={typeUser === "follow" && "active"}
                    onClick={() => onChangeType("follow")}
                    >
                    Siguiendo
                </Button>
                <Button 
                    className={typeUser === "new" && "active"}
                    onClick={() => onChangeType("new")}
                    >
                    Nuevos
                </Button>
            </ButtonGroup>
            {!users ? (
                <div className="users__loading">
                    <Spinner animation="border" variant="info"></Spinner>
                    Buscando usuarios
                </div>
            ): (
                <div>
                    <ListUser users={users}></ListUser>
                    <Button onClick={moreData} className="load-more">
                        {!btnLoading ? (
                            btnLoading !== 0 && "Cargar mas usuarios"
                        ): <Spinner as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"></Spinner>}
                    </Button>
                </div>

            )}
        </BasicLayout>
    )
}

function useUserQuery(location) {
    const { page = 1, type = "follow", search = ""} = queryString.parse(location.search)
    return { page, type, search };

}

export default withRouter(Users);