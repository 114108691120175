import React from 'react'
import moment from 'moment';
import localization from 'moment/locale/es'
import { Location, Link, DateBirth } from '../../../utils/icon';
import "./infoUser.scss"
import { size } from 'lodash';


export default function InfoUser(props) {
    const { user, followers, following } = props;
    return (
        <div className="info-user">
            <h2 className="name">
                {user?.name} {user?.surname}
            </h2>
            <p className="email">
                {user?.email}
            </p>
            {user?.biography && (
                <div className="description">{user.biography}</div>
            )}
            <div className="follow">
                <p><span>{size(following)}</span> Siguiendo</p>
                <p><span>{size(followers)}</span> Seguidores</p>
            </div>
            <div className="more-info">
                {user?.location && (
                    <p>
                        <Location/>
                        {user.location}
                    </p>
                )}
                {user?.website && (
                    <a href={user.website} alt={user.website} 
                    target="_blank" rel="noopener noreferrer"
                    >
                        <Link></Link> {user.website}
                    </a>
                )}
                {user?.birthdate && (
                    <p>
                        <DateBirth/>
                        {moment(user.birthdate).locale("es", localization).format("LL")}
                    </p>
                )}
            </div>
        </div>
    )
}
