import React, { Fragment, useState } from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch, faUsers, faComment } from '@fortawesome/free-solid-svg-icons'

import LogoTwitter from "../../assets/png/logo.png"
import LogoWhiteTwitter from "../../assets/png/logo-white.png"

import "./login.scss"
import BasicModal from '../../components/modal/basicModal/basicModal'
import SignForm from '../../components/signForm/signForm'
import LoginForm from '../../components/loginForm/loginForm'

export default function Login(props) {
    const { setRefreshLogin } = props;
    const [showModal, setshowModal] = useState(false);
    const [ contentModal, setcontentModal] = useState(null);

    const openModal = content => {
        setshowModal(true);
        setcontentModal(content);
    }
    return (
        <Fragment>
            <Container className="signin-signup" fluid>
                <Row>
                    <LeftComponent/>
                    <RightComponent
                        openModal={openModal}
                        setshowModal={setshowModal}
                        setRefreshLogin={setRefreshLogin}
                    />
                </Row>
            </Container>
            <BasicModal show={showModal} setShow={setshowModal}>
                {contentModal}
            </BasicModal>
        </Fragment>
    )
}

function LeftComponent() {
    return (
        <Col className="signin-signup__left" xs={6}>
            <img src={LogoTwitter} alt="Twittea"></img>
            <div>
                <h2>
                    <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
                    Sigue lo que te interesa.
                </h2>
                <h2>
                    <FontAwesomeIcon icon={faUsers}></FontAwesomeIcon>
                    Enterate de qué está hablando la gente.
                </h2>
                <h2>
                    <FontAwesomeIcon icon={faComment}></FontAwesomeIcon>
                    Unete a la conversacion.
                </h2>
            </div> 
        </Col>
    )
}

function RightComponent(props) {
    const { openModal, setshowModal, setRefreshLogin } = props;

    return (
        <Col className="signin-signup__right" xs={6}>
            <div>
                <img src={LogoWhiteTwitter} alt="twittor"></img>
                <h2>Mira lo que está pasando en el mundo en este momento</h2>
                <h3>Únete a Twittor hoy mismo</h3>
                <Button 
                    variant="primary"
                    onClick={() => openModal(<SignForm setshowModal={setshowModal}></SignForm>)}>
                    Regístrate
                </Button>
                <Button 
                    variant="outline-primary"
                    onClick={() => openModal(<LoginForm setRefreshLogin={setRefreshLogin}></LoginForm>)}>
                    Iniciar Sesión
                </Button>
            </div>
        </Col>
    )
}