import React, { useState, useEffect } from 'react'
import { Button, Spinner } from 'react-bootstrap';
import { getTweetsFollowersApi } from '../../api/tweet';
import ListTweets from '../../components/listTweets/listTweets';
import ListApiTweets from '../../components/listTweets/listApiTweets';
import BasicLayout from '../../layout/basicLayout/basicLayout';
import "./home.scss"

export default function Home(props) {
    const { setRefreshLogin } = props;
    const [ tweets, setTweets] = useState(null)
    const [ page, setPage] = useState(1)
    const [ loadingTweets, setLoadingTweets] = useState(false)

    useEffect(() => {
        getTweetsFollowersApi(page).then(response => {
            if (!tweets && response) {
                setTweets(formatModel(response))
            } else {
                if (!response) {
                    setLoadingTweets(0);
                } else {
                    const data = formatModel(response)
                    setTweets([...tweets, ...data])
                    setLoadingTweets(false)
                }
            }
        })
        .catch(() => {})
    }, [page])
    
    const moreData = () => {
        setLoadingTweets(true)
        setPage(page + 1)

    }
    return (
        <div>
            <BasicLayout className="home" setRefreshLogin={setRefreshLogin}>
                <div className="home__title">
                    <h2>Inicio</h2>
                </div>
                {tweets && <ListApiTweets tweets={tweets}></ListApiTweets>}
                <Button onClick={moreData} className="load-more">
                    {!loadingTweets ? (
                        loadingTweets !==0 ? "Obtener mas tweets": "No hay mas tweets"
                    ): (
                        <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"></Spinner>
                    )}
                </Button>
            </BasicLayout>
        </div>
    )
}

function formatModel(tweets) {
    const tweetsTemp = []
    tweets.forEach(tweet => {
        tweetsTemp.push({
            _id: tweet.Tweet._id,
            userid: tweet.userrelation,
            message: tweet.Tweet.message,
            date: tweet.Tweet.date
        })
    })
    return tweetsTemp;
}