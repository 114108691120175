import { Button, Spinner } from 'react-bootstrap';
import React, { useState, useEffect, Suspense } from 'react';
import { withRouter } from 'react-router'
import { toast } from 'react-toastify'
import { getUserTweetsApi } from '../../api/tweet';
import { getUserApi } from '../../api/user'
// import ListTweets from '../../components/listTweets/listTweets';
import userAuth from '../../hooks/userAuth'
import BasicLayout from '../../layout/basicLayout/basicLayout'
import InfoUser from './infoUser/infoUser'
import Portal from './portal'
import "./user.scss"
import { getFollowersApi, getFollowingApi } from '../../api/follow';
const ListTweets = React.lazy(() => import('../../components/listTweets/listTweets'))


function User(props) {
    const { match, setRefreshLogin } = props;
    const [ user, setUser] = useState(null)
    const [tweets, setTweets] = useState(null)
    const [page, setPage] = useState(1)
    const [loadingTweet, setLoadingTweet] = useState(false)
    const [ followers, setFollowers ] = useState(null)
    const [ following, setFollowing ] = useState(null)
    const { params } = match;
    const loggedUser = userAuth();

    useEffect(() => {
        getUserApi(params.id)
            .then(response => {
                if (!response) toast.error("El usuario que has buscado no existe")
                setUser(response)
            })
            .catch(() => {
                toast.error("El usuario que has buscado no existe")
            })
    }, [params])

    useEffect(() => {
        getFollowersApi(params.id)
            .then(response => {
                console.log("Followers ", response)
                //if (!response) toast.error("El usuario no tiene seguidoress")
                setFollowers(response)
            })
            .catch(() => {
                toast.error("El usuario no existe")
            })
    }, [params])

    useEffect(() => {
        getFollowingApi(params.id)
            .then(response => {
                console.log("Following ", response)
                //if (!response) toast.error("El usuario no sigue a nadie")
                setFollowing(response)
            })
            .catch(() => {
                toast.error("El usuario no existe")
            })
    }, [params])

    useEffect(() => {
        getUserTweetsApi(params.id, 1)
            .then(response => {
                console.log(response)
                setTweets(response);
            })
            .catch(() => {
                setTweets([])
            })
    }, [params])

    const moreData = () => {
        const pageTemp = page + 1;
        setLoadingTweet(true)
        getUserTweetsApi(params.id, pageTemp).then(response => {
            if (!response) {
                setLoadingTweet(0)
            } else {
                setTweets([...tweets, ...response])
                setPage(pageTemp)
                setLoadingTweet(false)
            }
        })
    }
    return (
        <BasicLayout className="user" setRefreshLogin={setRefreshLogin}>
            <div className="user__title">
                <h2>
                    { user ? `${user.name} ${user.surname}`: "El usuario no existe " } 
                </h2>
            </div>
            <Portal user={user} loggedUser={loggedUser}></Portal>
            <InfoUser user={user} followers={followers} following={following}/>
            <div className="user__tweets">
                <h3>Tweets</h3>
                {tweets && (
                    <Suspense fallback={<div>Loading</div>}>
                        <ListTweets tweets={tweets}></ListTweets>
                    </Suspense>
                )}
                <Button onClick={moreData}>
                    {!loadingTweet ? (
                        loadingTweet !== 0 && 'Obtener mas tweets'
                    ): (
                        <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            arian-hidden="true"></Spinner>
                    )}
                </Button>
            </div>
        </BasicLayout>
    )
}

export default withRouter(User)
