import React, { useEffect, useState } from 'react'
import { map } from 'lodash'
import TweetInfo from '../tweet/tweetInfo/tweetInfo';
import { useHistory } from 'react-router-dom';
import { getUserApi } from '../../api/user';
import "./listQuoteTweets.scss"
import { Fragment } from 'react';
import TweetMenu from '../tweet/tweetMenu';


export default function ListQuoteTweets(props) {
    const { userInfo, tweet, quoteTweets } = props;
    return (
        <div className="list-quote-tweets">
            {map(quoteTweets, (quoteTweet, index) => (
                <QuoteTWeet key={index} tweet={tweet} quoteTweet={quoteTweet} userInfo={userInfo}></QuoteTWeet>
            ))}
        </div>
    )
}

function QuoteTWeet(props) {
    const { quoteTweet, userInfo, tweet } = props;
    const [userQuote, setUserQuote] = useState(null)
    let history = useHistory();
    const redirectTweet = (tweet) => {
        history.push(`/tweet/${tweet._id}`)
    }

    /*useEffect(() => {
        console.log('Aqui')
        getUserApi(quoteTweet.userid).then(response => {
            setUserQuote(response)
        })
    }, [quoteTweet.userid])*/

    return (
        <div onClick={() => redirectTweet(tweet)} className="tweet">
            <TweetInfo userInfo={userQuote} tweet={quoteTweet}>
                <div className="tweet-quoted">
                    <TweetInfo userInfo={userInfo} tweet={tweet}></TweetInfo>
                </div>
            </TweetInfo>
        </div>
    )
}
