import moment from 'moment'
import React from 'react'
import { Image } from 'react-bootstrap'
import { replaceURLWithHTMLLinks } from '../../../utils/function';
import AvatarNotFound from "../../..//assets/png/avatar-no-found.png";
import "./tweetInfo.scss"

export default function TweetInfo(props) {
    const { userInfo, tweet, children } = props
    return (
        <div className="tweet-info">
            <Image className="avatar" src={tweet.UserId?.avatar ? tweet.UserId.avatar: AvatarNotFound} roundedCircle/>
            <div>
                <div className="name">
                    {tweet.UserId?.name} {tweet.UserId?.surname}
                    <span>{moment(tweet.date).calendar()}</span>
                </div>
                <div>
                    <div dangerouslySetInnerHTML={{ __html: replaceURLWithHTMLLinks(tweet.message)}}></div>
                    {children}
                </div>
            </div>
        </div>
    )
}
