import React, { useState } from 'react'
import { Row, Col, Form, Button, Spinner } from "react-bootstrap"
import "./signForm.scss";
import { values, size } from "lodash";
import { toast } from "react-toastify";
import { isEmailValid } from '../../utils/validations';
import { signUp } from '../../api/auth';

export default function SignForm(props) {
    const { setshowModal } = props;
    const [formData, setFormData] = useState(initialFormValue())
    const [loading, setLoading] = useState(false);

    const onSubmit = (e) => {
        e.preventDefault();
        let validCount = 0;
        values(formData).some(value => {
            value && validCount++
            return null
        })
        
        if (validCount !== size(formData)) {
            toast.warning("Completa todos los campos")
        } else {
            if (!isEmailValid(formData.email)) {
                toast.warning("Email Invalido")
            } else if (formData.password !== formData.repeatpass){
                toast.warning("Las contraseñas deben ser iguales")
            } else if (size(formData.password) < 6) {
                toast.warning("La contraseña tiene que tener al menos 6 caracteres")
            } else {
                setLoading(true);
                signUp(formData).then(response => {
                    console.log("Segundo response",response)
                    if (response.code) {
                        toast.warning(response.message)
                    } else {
                        toast.success("El registro ha sido correcto")
                        setshowModal(false);
                        setFormData(initialFormValue())
                    }
                }).catch(console.log)
                .finally(() => setLoading(false))
                toast.success("Form ok")
            }
        }
        console.log(validCount)
    };

    const onChange = e => {
        setFormData({...formData, [e.target.name]: e.target.value})
    }

    return (
        <div className="sign-up-form">
            <h2>Crea tu cuenta</h2>
            <Form onSubmit={onSubmit} onChange={onChange}>
                <Form.Group>
                    <Row>
                        <Col>
                            <Form.Control
                                type="text"
                                placeholder="Nombre"
                                defaultValue={formData.name}
                                name="name">
                            </Form.Control>
                        </Col>
                        <Col>
                            <Form.Control
                                type="text"
                                placeholder="Apellidos"
                                defaultValue={formData.surname}
                                name="surname">
                            </Form.Control>
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group>
                    <Form.Control
                        type="email"
                        placeholder="Correo Electrónico"
                        defaultValue={formData.email}
                        name="email">
                    </Form.Control>
                </Form.Group>
                <Form.Group>
                    <Row>
                        <Col>
                            <Form.Control
                                type="password"
                                placeholder="Contraseña"
                                name="password"
                                defaultValue={formData.password}>
                            </Form.Control>
                        </Col>
                        <Col>
                            <Form.Control
                                type="password"
                                placeholder="Repetir Contraseña"
                                name="repeatpass"
                                defaultValue={formData.repeatpass}>
                            </Form.Control>
                        </Col>
                    </Row>
                </Form.Group>
                <Button variant="primary" type="submit">
                    {!loading ? "Registrarse": <Spinner animation="border"/>}
                </Button>
            </Form>
        </div>
    )
}

function initialFormValue() {
    return {
        name: "",
        surname: "",
        email: "",
        password: "",
        repeatpass: ""
    }
}
