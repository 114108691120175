import React, { useState,useEffect } from 'react'
import Login from './page/login';
import { ToastContainer } from "react-toastify";
import { AuthContext } from './utils/context';
import { isUserLoged } from './api/auth';
import Routing from './routes/routing';

export default function App() {
  const [user, setUser] = useState(null);
  const [loadUser, setLoadUser] = useState(false)
  const [refreshLogin, setRefreshLogin] = useState(false)

  useEffect(() => {
    setUser(isUserLoged());
    setRefreshLogin(false);
    setLoadUser(true)
  }, [refreshLogin])

  if (!loadUser) return null;

  return (
      <AuthContext.Provider value={user}>
        {user ? <Routing setRefreshLogin={setRefreshLogin}></Routing>: <Login setRefreshLogin={setRefreshLogin}/>}
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover/>
      </AuthContext.Provider>
  )

}