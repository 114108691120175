import { faHome, faPowerOff, faUser, faUsers } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import LogoWhite from "../../assets/png/logo-white.png"
import "./leftMenu.scss"
import { logout } from '../../api/auth'
import userAuth from '../../hooks/userAuth'
import TweetModal from '../modal/tweetModal'

export default function LeftMenu(props) {
    const { setRefreshLogin } = props;
    const [showModal, setShowModal] = useState(false)
    const user = userAuth();
    const logoutSession = () => {
        logout()
        setRefreshLogin(true);
    }
    return (
        <div className="left-menu">
            <img className="logo" src={LogoWhite} alt="twittor"></img>
            <Link to="/">
                <FontAwesomeIcon icon={faHome}></FontAwesomeIcon>Inicio
            </Link>
            <Link to="/users">
                <FontAwesomeIcon icon={faUsers}></FontAwesomeIcon>Usuarios
            </Link>
            <Link to={`/user/${user?._id}`}>
                <FontAwesomeIcon icon={faUser}></FontAwesomeIcon>Perfil
            </Link>
            <Link to="/logout" onClick={logoutSession}>
                <FontAwesomeIcon icon={faPowerOff}></FontAwesomeIcon>Cerrar Sesión
            </Link>
            <Button onClick={() => setShowModal(true)}>Twittoar</Button>
            <TweetModal show={showModal} setShow={setShowModal}></TweetModal>
        </div>
    )
}
