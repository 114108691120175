import React, { useState } from 'react'
import { Button, Form, Image, Modal } from 'react-bootstrap'
import userAuth from '../../../hooks/userAuth';
import { Close, Emoticon } from '../../../utils/icon';
import "./quoteModal.scss"
import AvatarNotFound from '../../../assets/png/avatar-no-found.png'
import TweetInfo from '../../tweet/tweetInfo/tweetInfo';
import Picker from "emoji-picker-react";
import { addQuoteTweet } from '../../../api/tweet_menu';


export default function QuoteModal(props) {
    const { show, setShow, tweet, userInfo, updateQuote, setUpdateQuote } = props;
    const [message, setMessage] = useState("")
    const [showPicker, setShowPicker] = useState(false)
    const user = userAuth()

    const addEmoji = (event, emojiObject) => {
        const updateMessage = `${message}${emojiObject.emoji}`
        setMessage(updateMessage)
        document.getElementById("quote").value = updateMessage
    }

    const onSubmit = (e) => {
        e.preventDefault()
        addQuoteTweet(tweet._id, message)
                .then(response => {
                    console.log(response)
                    setShow(false)
                    setUpdateQuote(!updateQuote)
                })
                .catch(err => {
                    console.log("Ocurrio un error")
                })
    }

    return (
        <Modal 
            className="quote-modal"
            show={show}
            onHide={() => setShow(false)}
            centered
            size="lg">
            <Modal.Header>
                <Modal.Title>
                    <Close onClick={() => setShow(false)}></Close>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="quote-form">
                    <Image className="avatar" src={user.avatar ? user.avatar: AvatarNotFound } roundedCircle/>
                    <Form onSubmit={onSubmit}>
                        <Form.Group>
                            <Form.Control
                                as="textarea"
                                rows="2"
                                placeholder="Escribe tu comentario"
                                name="quote"
                                id="quote"
                                onChange={e => setMessage(e.target.value)}>
                            </Form.Control>
                        </Form.Group>
                        <div className="tweet-quoted">
                            <TweetInfo userInfo={userInfo} tweet={tweet}></TweetInfo>
                        </div>
                        <div className="menu">
                            <Emoticon onClick={() => setShowPicker(!showPicker)}></Emoticon>
                            <Button
                                type="submit">
                                    Comentar
                            </Button>
                        </div>
                        {showPicker && <Picker onEmojiClick={addEmoji}></Picker>}
                    </Form>               
                </div>
            </Modal.Body>
    </Modal>
    )
}
