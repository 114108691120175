import React, { useEffect, useState} from 'react'
import { getCommentsTweets } from '../../../api/tweet_menu'
import ListTweets from '../../listTweets'
import "./tweetComments.scss"

export default function TweetComments(props) {
    const { tweet } = props
    const [comments, setComments] = useState(null)
    const [updateComment, setUpdateComment] = useState(false)

    useEffect(() => {
        getCommentsTweets(tweet._id).then(response => {
            if (response) {
                setComments(response)
            }
        })
    }, [tweet, updateComment])

    return (
        <div className="tweet-comment">
            <ListTweets tweets={comments}></ListTweets>
        </div>
    )
}
