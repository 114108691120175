import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap';
import { checkFollowApi, followUserApi, unfollowUserApi } from '../../../api/follow';
import ConfigModal from '../../../components/modal/configModal/configModal';
import { API_HOST } from '../../../utils/constant';
import EditUserForm from '../editUserForm';
import "./portal.scss"

export default function Portal(props) {
    const { user, loggedUser } = props;
    const [showModal, setshowModal] = useState(false);
    const [following, setFollowing] = useState(null);
    const [reloadFollow, setReloadFollow] = useState(false)

    useEffect(() => {
        if (user) {
            checkFollowApi(user?.id).then(response => {
                if (response?.status) {
                    setFollowing(true)
                } else {
                    setFollowing(false)
                }
            })
        }
        setReloadFollow(false)
    }, [user, reloadFollow])

    const onFollow = () => {
        followUserApi(user.id).then(() => {
            console.log("TODO OK")
            setReloadFollow(true)
        })
    }
    const onUnFollow = () => {
        unfollowUserApi(user.id).then(() => {
            console.log("TODO OK")
            setReloadFollow(true)
        })
    }

    return (
        <div className="banner" style={{ backgroundImage: `url('${user?.banner}')`}}>
            <div 
                className="avatar"
                style={{ backgroundImage: `url('${user?.avatar}')`}}>
            </div>
            { user && (
                <div className="options">
                    {loggedUser._id === user.id && <Button onClick={() => setshowModal(true)}>Editar Perfil</Button> }
                    {loggedUser._id !== user.id && (
                        following !== null && (
                           (following ? 
                           <Button onClick={onUnFollow} className="unfollow">
                               <span>Siguiendo</span>
                            </Button>
                           : <Button onClick={onFollow}>Seguir</Button>)
                        )
                    )}
                </div>
            )}

            <ConfigModal show={showModal} setShow={setshowModal} title="Editar perfil">
                <EditUserForm user={user} setshowModal={setshowModal}/>
            </ConfigModal>
        </div>
    )
}
