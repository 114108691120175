import { map } from 'lodash'
import React from 'react'
import "./listTweets.scss"
import BasicApiTweet from '../tweet/basicTweet/basicApiTweet'

export default function ListApiTweets(props) {
    const { tweets } = props;
    return (
        <div className="list-tweets">
            {map(tweets, (tweet, index) => (
                <BasicApiTweet key={index} tweet={tweet}></BasicApiTweet>
            ))}
        </div>
    )
}
