import React, { useState, useCallback } from 'react'
import { Form, Button, Row, Col, Spinner } from 'react-bootstrap'
import { es } from 'date-fns/locale'
import DatePicker from 'react-datepicker'
import "./editUserForm.scss"
import { useDropzone } from "react-dropzone"
import { Camera } from '../../../utils/icon'
import { uploadBannerApi, uploadAvatarApi, updateInfoApi } from '../../../api/user'
import { toast } from 'react-toastify'

export default function EditUserForm(props) {
    const { user, setshowModal} = props;
    const [formData, setFormData] =  useState(initialValue(user))
    const [ bannerUrl, setBannerUrl] = useState(user.banner)
    const [ bannerFile, setBannerFile ] = useState(null)
    const [ avatarUrl, setAvatarUrl] = useState(user.avatar)
    const [ avatarFile, setAvatarFile ] = useState(null)
    const [ loading, setLoading] = useState(false)

    const onDropBanner = useCallback(acceptedfile => {
        const file = acceptedfile[0];
        setBannerUrl(URL.createObjectURL(file))
        setBannerFile(file)
    })

    const { getRootProps: getRootBanner, getInputProps: getInputBanner } = useDropzone({
        accept: "image/jpeg, image/png",
        noKeyboard: true,
        multiple: false,
        onDrop: onDropBanner
    })

    const onDropAvatar = useCallback(acceptedfile => {
        const file = acceptedfile[0];
        setAvatarUrl(URL.createObjectURL(file))
        setAvatarFile(file)
    })

    const { getRootProps: getRootAvatar, getInputProps: getInputAvatar } = useDropzone({
        accept: "image/jpeg, image/png",
        noKeyboard: true,
        multiple: false,
        onDrop: onDropAvatar
    })

    const onChange = e => {
        setFormData({...formData, [e.target.name]: e.target.value})
    }

    const onSubmit = async e => {
        e.preventDefault();
        setLoading(true)
        if (bannerFile) {
            await uploadBannerApi(bannerFile).catch(() => {
                toast.error("Error al subir el nuevo banner")
            })
        }
        if (avatarFile) {
            await uploadAvatarApi(avatarFile).catch(() => {
                toast.error("Error al subir el nuevo avatar")
            })
        }
        await updateInfoApi(formData).then(() => {
            setshowModal(false)
        }).catch(() => {
            toast.error("Error al actualizar la informacion del usuario")
        })
        setLoading(false);
        window.location.reload()
    }
    return (
        <div className="edit-user-form">
            <div 
                className="banner" 
                style={{ backgroundImage: `url('${bannerUrl}')`}}
                {...getRootBanner()}>
                    <input {...getInputBanner()}></input>
                    <Camera/>
            </div>
            <div 
                className="avatar" 
                style={{ backgroundImage: `url('${avatarUrl}')`}}
                {...getRootAvatar()}>
                    <input {...getInputAvatar()}></input>
                    <Camera/>
            </div>
            <Form onSubmit={onSubmit}>
                <Form.Group>
                    <Row>
                        <Col>
                            <Form.Control 
                                type="text"
                                placeholder="Nombre"
                                name="name"
                                defaultValue={formData.name}
                                onChange={onChange}>
                            </Form.Control>
                        </Col>
                        <Col>
                            <Form.Control 
                                type="text"
                                placeholder="Apellido"
                                name="surname"
                                defaultValue={formData.surname}
                                onChange={onChange}>
                            </Form.Control>
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group>
                    <Form.Control
                        as="textarea"
                        row="3"
                        placeholder="Agrega tu biografia"
                        type="text"
                        name="biography"
                        defaultValue={formData.biography}
                        onChange={onChange}>
                        
                    </Form.Control>
                </Form.Group>
                <Form.Group>
                    <Form.Control
                        type="text"
                        placeholder="Sitio Web"
                        name="website"
                        defaultValue={formData.website}
                        onChange={onChange}>

                    </Form.Control>
                </Form.Group>
                <Form.Group>
                    <DatePicker
                        placeholder="Fecha de Nacimiento"
                        locale={es}
                        selected={new Date(formData.birthdate)}
                        onChange={date => setFormData({...formData, birthdate: date})}>
                    </DatePicker>
                </Form.Group>
                <Button className="btn-submit" variant="primary" type="submit">
                    { loading && <Spinner animation="border" size="sm"></Spinner>}
                    Actualizar
                </Button>
            </Form>
        </div>
    )
}

function initialValue(user) {
    return {
        name: user.name || "",
        surname: user.surname || "",
        biography: user.biography || "",
        location: user.location || "",
        website: user.website || "",
        birthdate: user.birthdate || ""
    }
}
