import React, { useState, useEffect } from 'react'
import TweetInfo from '../tweetInfo'
import TweetMenu from '../tweetMenu'
import "./basicTweet.scss";
import { getUserApi } from "../../../api/user";
import { useHistory } from 'react-router-dom';


export default function BasicTweet(props) {

    const { tweet } = props;
    const [userInfo, setUserInfo] = useState(null)
    let history = useHistory()

    /*useEffect(() => {
        getUserApi(tweet.userid).then(response => {
            console.log("View profile")
            setUserInfo(response)
        })
    }, [tweet.userid])*/

    const redirectTweet = (tweet) => {
        history.push(`/tweet/${tweet._id}`)
    }
 
    return (
        <div className="basic-tweet">
                <div onClick={() => redirectTweet(tweet)}>
                    <TweetInfo userInfo={userInfo} tweet={tweet}></TweetInfo>
                </div>
                <TweetMenu userInfo={userInfo} tweet={tweet}></TweetMenu>
        </div>
    )
}
