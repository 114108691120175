import { API_HOST } from "../utils/constant";
import { getToken } from "./auth";

export function getUserApi(id) {
    const url = `${API_HOST}/view-profile?id=${id}`
    const params = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`
        }
    }
    return fetch(url, params)
            .then(response => {
                if (response.status >= 400) throw null;
                return response.json()
            })
            .then(result => result)
            .catch(error => {
                return error
            })
}

export function uploadBannerApi(file) {
    const url = `${API_HOST}/upload-banner`;
    const formData = new FormData();
    formData.append("banner", file);
    const params = {
        method: "POST",
        headers: {
            Authorization: `Bearer ${getToken()}`
        },
        body: formData
    }

    return fetch(url, params)
            .then(response => {
                return response.json()
            })
            .then(result => result)
            .catch(err => {
                return err;
            })
}

export function uploadAvatarApi(file) {
    const url = `${API_HOST}/upload-avatar`;
    const formData = new FormData();
    formData.append("avatar", file);
    const params = {
        method: "POST",
        headers: {
            Authorization: `Bearer ${getToken()}`
        },
        body: formData
    }

    return fetch(url, params)
            .then(response => {
                return response.json()
            })
            .then(result => result)
            .catch(err => {
                return err;
            })
}

export function updateInfoApi(data) {
    const url = `${API_HOST}/edit-profile`
    const params = {
        method: "PUT",
        headers: {
            Authorization: `Bearer ${getToken()}`
        },
        body: JSON.stringify(data)
    }

    return fetch(url, params)
            .then(response => {
                return response
            })
            .catch(error => error)
}