import React, { useEffect, useState } from 'react';
import { getLikesTweet, getQuoteTweets, getRetweets } from '../../../api/tweet_menu';
import "./tweetStadistic.scss"
import UsersModal from '../../modal/usersModal/usersModal';
import QuoteTweetsModal from '../../modal/quoteTweetsModal/quoteTweetsModal';

export default function TweetStadistic(props) {
    const { tweet, userInfo } = props;
    const [quoteTweets, setQuoteTweets] = useState([])
    const [likes, setLikes] = useState([])
    const [retweets, setRetweets] = useState([])
    const [showModalQuote, setShowModalQuote] = useState(false)
    const [showModalLike, setShowModalLike] = useState(false)
    const [showModalRetweet, setShowModalRetweet] = useState(false)

    useEffect(() => {
        getQuoteTweets(tweet._id)
            .then(response => {
                if (response) {
                    console.log("Quote Tweets ", response)
                    setQuoteTweets(response)
                }
            })
    }, [tweet])

    useEffect(() => {
        getRetweets(tweet._id).then(response => {
            if (response) {
                setRetweets(response)
            }
        })
    }, [tweet])

    useEffect(() => {
        getLikesTweet(tweet._id).then(response => {
            console.log("Likes", response)
            if (response) {
                setLikes(response)
            }
        })
    }, [tweet])

    return (
        <div className="tweet-stadistic">
            {retweets.length === 0 ? "": (
                <div onClick={() => setShowModalRetweet(true)}>
                    <span>{retweets.length}</span> Retweets
                </div>
            )}
            {quoteTweets.length === 0 ? "": (
                <div onClick={() => setShowModalQuote(true)}>
                    <span>{quoteTweets.length}</span> Quote Tweets
                </div>
            )}
            {likes.length === 0 ? "": (
                <div onClick={() => setShowModalLike(true)}>
                    <span>{likes.length}</span> likes
                </div>
            )}
            <UsersModal
                show={showModalLike}
                setShow={setShowModalLike}
                users={likes}
                title={"Liked by"}></UsersModal>
            <UsersModal
                show={showModalRetweet}
                setShow={setShowModalRetweet}
                users={retweets}
                title={"Retweets by"}></UsersModal>
            <QuoteTweetsModal
                show={showModalQuote}
                setShow={setShowModalQuote}
                quoteTweets={quoteTweets}
                tweet={tweet}
                userInfo={userInfo}
                title={"QuoteTweets by"}></QuoteTweetsModal>
        </div>

    )
}
