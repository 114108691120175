import React, { useState } from 'react'
import { Button, Col, Form, Image, Modal, Row } from 'react-bootstrap';
import userAuth from '../../../hooks/userAuth';
import { Close, Emoticon } from '../../../utils/icon';
import TweetInfo from '../../tweet/tweetInfo/tweetInfo';
import "./commentModal.scss"
import Picker from "emoji-picker-react";
import { addCommentTweet } from '../../../api/tweet_menu';
import AvatarNotFound from '../../../assets/png/avatar-no-found.png'


export default function CommentModal(props) {
    const { show, setShow, tweet, userInfo, setUpdateComment, updateComment } = props;
    const user = userAuth()
    const [message, setMessage] = useState("")
    const [showPicker, setShowPicker] = useState(false)

    const onSubmit = (e) => {
        e.preventDefault()
        addCommentTweet(tweet._id, message)
            .then(response => {
                console.log(response)
                setShow(false)
                setUpdateComment(!updateComment)
            })
            .catch(err => {
                console.log("Ocurrio un error")
            })
    }

    const addEmoji = (event, emojiObject) => {
        const updateMessage = `${message}${emojiObject.emoji}`
        setMessage(updateMessage)
        document.getElementById("comment").value = updateMessage
    }

    return (
        <Modal
            className="comment-modal"
            show={show}
            onHide={() => setShow(false)}
            centered
            size="lg">
            <Modal.Header>
                <Modal.Title>
                    <Close onClick={() => setShow(false)}></Close>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <TweetInfo userInfo={userInfo} tweet={tweet}></TweetInfo>
                <div className="comment-form">
                    <Image className="avatar" src={user.avatar ? user.avatar: AvatarNotFound } roundedCircle/>
                    <Form onSubmit={onSubmit}>
                        <Form.Group>
                            <Row>
                                <Col>
                                    <Form.Control
                                        as="textarea"
                                        rows="2"
                                        placeholder="Escribe tu comentario aquí"
                                        name="comment"
                                        id="comment"
                                        onChange={e => setMessage(e.target.value)}>
                                    </Form.Control>
                                </Col>
                            </Row>
                        </Form.Group>
                        <div className="menu">
                            <Emoticon onClick={() => setShowPicker(!showPicker)}></Emoticon>
                            <Button
                                type="submit">
                                    Comentar
                            </Button>
                        </div>
                        {showPicker && <Picker onEmojiClick={addEmoji}></Picker>}
                    </Form>
                </div>
            </Modal.Body>
        </Modal>
    )
}
