import { values, size } from 'lodash'
import React, { useState } from 'react'
import { Form, Button, Spinner } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { login, setToken } from '../../api/auth'
import { isEmailValid } from '../../utils/validations'


import "./loginForm.scss"

export default function LoginForm(props) {
    const { setRefreshLogin } = props;
    const [formData, setFormData] = useState(initialFormValue())
    const [loading, setLoading] = useState(false)
    const onSubmit = e => {
        e.preventDefault();
        console.log(formData)
        let validCount = 0;
        values(formData).some(value => {
            value && validCount++;
            return null;
        })
        if (size(formData) !== validCount) {
            toast.warning("Completa todos los campos del formulario")
        } else {
            if (!isEmailValid(formData.email)) {
                toast.warning("Email es invalido")
            } else {
                setLoading(true)
                toast.success("form login ok")
                login(formData).then(response => {
                    if (response.message) {
                        toast.warning(response.message)
                    } else {
                        setToken(response.Token)
                        setRefreshLogin(true)
                    }
                })
                .catch(() => {
                    toast.error("Error intentelo, mas tarde")
                })
                .finally(() => {
                    setLoading(false)
                })
            }
        }
    }

    const onChange = e => {
        setFormData({...formData, [e.target.name]: e.target.value})
    }

    return (
        <div className="sign-in-form">
            <h2>Entrar</h2>
            <Form onSubmit={onSubmit} onChange={onChange}>
                <Form.Group>
                    <Form.Control 
                        type="email"
                        name="email"
                        defaultValue={formData.email}
                        placeholder="Correo electronico"/>
                </Form.Group>
                <Form.Group>
                    <Form.Control 
                        type="password"
                        name="password"
                        defaultValue={formData.password}
                        placeholder="Constraseña"/>
                </Form.Group>
                <Button variant="primary" type="submit">
                    {!loading ? "Iniciar sesión": <Spinner animation="border"></Spinner>}
                </Button>
            </Form>
        </div>
    )
}

function initialFormValue() {
    return {
        email: "",
        password: ""
    }
}
