import { map } from 'lodash'
import React from 'react'
import BasicTweet from '../tweet/basicTweet/basicTweet'
import "./listTweets.scss"

export default function ListTweets(props) {
    const { tweets } = props;
    return (
        <div className="list-tweets">
            {map(tweets, (tweet, index) => (
                <BasicTweet key={index} tweet={tweet}></BasicTweet>
            ))}
        </div>
    )
}
