import { map } from 'lodash-es';
import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap';
import { addlikeTweet, addRetweet, getCommentsTweets, getLikesTweet, getQuoteTweets, getRetweet, getRetweets, removeLikeTweet } from '../../../api/tweet_menu'
import userAuth from '../../../hooks/userAuth';
import { Comment, Like, Liked, Quote, Retweet } from '../../../utils/icon';
import CommentModal from '../../modal/commentModal/commentModal';
import QuoteModal from '../../modal/quoteModal/quoteModal';
import "./tweetMenu.scss"


export default function TweetMenu(props) {
    
    const { userInfo, tweet } = props;
    const [likes, setLikes] = useState([])
    const [isLike, setIsLike] = useState(false)
    const [updateLike, setUpdateLike] = useState(false)
    const [updateComment, setUpdateComment] = useState(false)
    const [updateQuote, setUpdateQuote] = useState(false)
    const [updateRetweet, setUpdateRetweet] = useState(false)
    const [comments, setComments] = useState([])
    const [quoteTweets, setQuoteTweets] = useState([])
    const [retweets, setRetweets] = useState([])
    const [isRetweet, setIsRetweet] = useState(false)
    const [showModalComment, setShowModalComment] = useState(false)
    const [showModalQuote, setShowModalQuote] = useState(false)

    
    const user = userAuth();

    useEffect(() => {
        getCommentsTweets(tweet._id).then(response => {
            if (response) {
                setComments(response)
            }
        })
    }, [tweet, updateComment])

    useEffect(() => {
        getQuoteTweets(tweet._id)
            .then(response => {
                if (response) {
                    setQuoteTweets(response)
                }
            })
    }, [tweet, updateQuote])

    useEffect(() => {
        getLikesTweet(tweet._id).then(response => {
            if (response) {
                setLikes(response)
                let flag = false;
                map(response, like => {
                    const { id } = like;
                    if (id === user?._id) {
                        flag = true
                    }
                })
                setIsLike(flag)
            } else {
                setIsLike(false)
                setLikes([])
            }
        })
    }, [tweet, updateLike])

    useEffect(() => {
        getRetweets(tweet._id).then(response => {
            if (response) {
                setRetweets(response)
                let flag = false;
                map(response, retweet => {
                    const { id } = retweet;
                    if (id === user?._id) {
                        flag = true
                    }
                })
                setIsRetweet(flag)
            } else {
                setIsRetweet(false)
                setRetweets([])
            }
        })
    }, [tweet, updateRetweet])

    const likeTweet = (tweet) => {
        const { _id } = tweet;
        addlikeTweet(_id).then(response => {
            setUpdateLike(!updateLike)
        })
        .catch(() => {})
    }

    const dislikeTweet = (tweet) => {
        const { _id } = tweet;
        removeLikeTweet(_id).then(response => {
            setUpdateLike(!updateLike)
        })
        .catch(() => {})
    }

    const retweetTweet = (tweet) => {
        const { _id } = tweet;
        addRetweet(_id).then(response => {
            console.log(response)
            setUpdateRetweet(!updateRetweet)
        })
    }

    return (
        <div className="tweet-menu">
            <div>
                <Comment as={Button} onClick={() => setShowModalComment(true)}></Comment> 
                <span>{comments.length === 0 ? "": comments.length}</span>
            </div>
            <div>
                {isRetweet ? (
                    <div className="active">
                        <Retweet onClick={() => retweetTweet(tweet)}></Retweet> <span>{retweets.length === 0 ? "": retweets.length}</span>
                    </div>
                ): (
                    <div className="no-active">
                        <Retweet onClick={() => retweetTweet(tweet)}></Retweet> <span>{retweets.length === 0 ? "": retweets.length}</span>
                    </div>
                )}
            </div>
            <div>
                <Quote onClick={() => setShowModalQuote(true)}></Quote>
                <span>{quoteTweets.length === 0 ? "": quoteTweets.length}</span>
            </div>
            <div>
                {isLike ? (
                    <div>
                        <Liked onClick={() => dislikeTweet(tweet)}></Liked> <span>{likes.length === 0 ? "": likes.length}</span>
                    </div>
                ): (
                <div>
                    <Like onClick={() => likeTweet(tweet)}></Like> <span>{likes.length === 0 ? "": likes.length}</span>
                </div>
                )}
            </div>
            <CommentModal 
                userInfo={userInfo} 
                tweet={tweet} 
                show={showModalComment} 
                setShow={setShowModalComment}
                updateComment={updateComment}
                setUpdateComment={setUpdateComment}></CommentModal>
            <QuoteModal
                userInfo={userInfo}
                tweet={tweet}
                show={showModalQuote}
                setShow={setShowModalQuote}
                updateQuote={updateQuote}
                setUpdateQuote={setUpdateQuote}>
            </QuoteModal>
        </div>
    )
}
