import React from 'react'
import { Link } from 'react-router-dom'
import Error400Image from "../../assets/png/error-404.png"
import Logo from "../../assets/png/logo.png"
import "./error404.scss"

export default function Error404() {
    return (
        <div className="error404">
            <img src={Logo} alt="Twittor"></img>
            <img src={Error400Image}></img>
            <Link to="/">Volver al inicio</Link>
        </div>
    )
}
