import Error404 from "../page/error404"
import Home from "../page/home"
import Tweet from "../page/tweet/tweet";
import User from "../page/user/user";
import Users from "../page/users/users";

// eslint-disable-next-line import/no-anonymous-default-export
export default [
    {
        path: "/",
        exact: true,
        page: Home
    },
    {
        path: "/users",
        exact: true,
        page: Users
    },
    {
        path: "/user/:id",
        exact: true,
        page: User
    },
    {
        path: "/tweet/:id",
        exact: true,
        page: Tweet
    },
    {
        path: "*",
        page: Error404
    }
];