import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom';
import { getTweetApi } from '../../api/tweet';
import { getUserApi } from '../../api/user';
import TweetInfo from '../../components/tweet/tweetInfo';
import TweetMenu from '../../components/tweet/tweetMenu';
import TweetComments from '../../components/tweet/tweetComments/tweetComments';
import TweetStadistic from '../../components/tweet/tweetStadistic/tweetStadistic';
import BasicLayout from '../../layout/basicLayout/basicLayout'
import "./tweet.scss"

function Tweet(props) {
    const { setRefreshLogin, match } = props;
    const { params } = match;
    const [ tweet, setTweet ] = useState(null)
    const [ userInfo, setUserInfo] = useState(null)

    useEffect(() => {
        getTweetApi(params.id).then(response => {
            console.log(response)
            setTweet(response)
        })
    }, [params])

    /*useEffect(() => {
        if (tweet) {
            console.log('Aqui en tweet')
            getUserApi(tweet?.userid).then(response => {
                console.log(response)
                setUserInfo(response)
            })
        }
    }, [tweet])*/

    return (
        <div>
            <BasicLayout className="ptweet" setRefreshLogin={setRefreshLogin}>
                <div className="ptweet__title">
                    <h2>Tweet</h2>
                </div>
                {tweet ? (
                    <div>
                        <TweetInfo userInfo={userInfo} tweet={tweet}></TweetInfo>
                        <TweetMenu userInfo={userInfo} tweet={tweet}></TweetMenu>
                        <TweetStadistic tweet={tweet} userInfo={userInfo}></TweetStadistic>
                        <div className="ptweet__comment">
                            <h4>Comentarios</h4>
                            <TweetComments tweet={tweet}></TweetComments>
                        </div>
                    </div>
                ): (
                    <h2>Cargando..</h2>
                )}
                
            </BasicLayout>
        </div>
    )
}

export default withRouter(Tweet)
