import { map, isEmpty } from 'lodash';
import React from 'react'
import "./listUser.scss"
import User from './user';

export default function ListUser(props) {
    const { users } = props;

    if (isEmpty(users)) {
        return <h2>No hay resultado </h2>
    }
    return (
        <ul className="list-users">
            {map(users, user => (
                <User key={user.id} user={user}></User>
            ))}
        </ul>
    )
}
