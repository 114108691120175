import { API_HOST, TOKEN } from "../utils/constant"
import jwtDecode from "jwt-decode";

export function signUp(user) {
    console.log(user)
    const url = `${API_HOST}/register`;
    const userTemp = {
        ...user,
        email: user.email.toLowerCase()
    };
    delete userTemp.repeatpass;
    console.log(userTemp)

    const params = {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(userTemp)
    }

    return fetch(url, params)
        .then(response => {
            console.log("Response", response)
            if (response.status === 201) {
                return response.json()
            }
            return { code: 404, message: "Email no disponible"}
        })
        .then(result => {
            return result;
        })
        .catch(console.log)
}

export function login(user) {
    const url = `${API_HOST}/login`;

    const data = {
        ...user,
        email: user.email.toLowerCase()
    };

    const params = {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
    };

    return fetch(url, params).then(response => {
        if (response.status === 200) {
            return response.json()
        }
        return { message: "Usuario o contraseña incorrecto"}
    })
    .then(result => {
        return result;
    })
    .catch(err => {
        return err;
    })
}

export function setToken(token) {
    localStorage.setItem(TOKEN, token)
}

export function logout() {
    localStorage.removeItem(TOKEN)
}

export function getToken() {
    return localStorage.getItem(TOKEN)
}

export function isUserLoged() {
    const token = getToken();
    if (!token) {
        logout()
        return null;
    }
    if (isExpireToken(token)) {
        logout()
    }
    return jwtDecode(token);

}

export function isExpireToken(token) {
    const { exp } = jwtDecode(token)
    const expire = exp * 1000;
    const timeout = expire - Date.now();
    if (timeout < 0) {
        return true;
    }
    return false
}