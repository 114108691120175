import React from 'react'
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom'
import { map } from 'lodash';
import configRoutes from './configRoutes';


export default function Routing(props) {
    const { setRefreshLogin } = props;
    return (
        <Router>
            <Switch>
                {map(configRoutes, (route, index) => (
                    <Route key={index} path={route.path} exact={route.exact}>
                        <route.page setRefreshLogin={setRefreshLogin}></route.page>
                    </Route>
                ))}
            </Switch>
        </Router>
    )
}
