import { API_HOST } from "../utils/constant";
import { getToken } from "./auth";

export function addTweetApi(message) {
    console.log(message)
    const url = `${API_HOST}/tweet`;
    const data = {
        message
    }

    const params = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`
        },
        body: JSON.stringify(data)
    }

    return fetch(url, params)
            .then(response => {
                if (response.status >= 200) {
                    return { code: response.status, message: "Tweet enviado"}
                }
                return { code: 500, message: "Error del servidor"}
            })
            .catch(err => {
                return err;
            })
}

export function getUserTweetsApi(idUser, page) {
    const url = `${API_HOST}/tweets?id=${idUser}&page=${page}`;
    const params = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`
        }
    }
    return fetch(url, params)
            .then(response => {
                return response.json()
            })
            .catch(err => {
                return err;
            })
}

export function getTweetsFollowersApi(page = 1) {
    const url = `${API_HOST}/list-tweets?page=${page}`;
    const params = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`
        }
    }
    return fetch(url, params)
            .then(response => {
                return response.json()
            })
            .catch(err => {
                return err;
            })
}

export function getTweetApi(id) {
    const url = `${API_HOST}/tweet?id=${id}`;
    const params = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`
        }
    }
    return fetch(url, params)
            .then(response => {
                return response.json()
            })
            .catch(err => {
                return err;
            })
}