import { API_HOST } from "../utils/constant";
import { getToken } from "./auth";

export function addlikeTweet(idTweet) {
    const url = `${API_HOST}/tweet/like`;
    const data = {
        tweet: idTweet
    }
    const params = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`
        },
        body: JSON.stringify(data)
    }

    return fetch(url, params)
            .then(response => {
                console.log(response.status)
                if (response.status === 201) {
                    return { "status": true}
                } else {
                    return { "status": false}
                }
            })
            .catch(err => {
                return err;
            })
}

export function getLikesTweet(idTweet) {
    const url = `${API_HOST}/tweet/likes?id=${idTweet}`
    const params = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`
        }
    }
    
    return fetch(url, params)
            .then(response => {
                return response.json()
            })
            .catch(err => {
                return err;
            })
}

export function removeLikeTweet(idTweet) {
    const url = `${API_HOST}/tweet/dislike?id=${idTweet}`;
    const params = {
        method: "DELETE",
        headers: {
            Authorization: `Bearer ${getToken()}`
        }
    }
    return fetch(url, params)
        .then(response => {
            if (response.status === 201) {
                return { status: true }
            } else {
                return { status: false}
            }
        })
        .catch(err => {
            return err;
        })
}

/*------------------COMENTARIO-------------------*/

export function addCommentTweet(idTweet, message) {
    const url = `${API_HOST}/tweet/comment`;
    const data = {
        message,
        twitter_comment: idTweet
    }

    const params = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`
        },
        body: JSON.stringify(data)
    }

    return fetch(url, params)
            .then(response => {
                if (response.status === 201) {
                    return { "status": true }
                } else {
                    return { "status": false }
                }
            })
            .catch(err => {
                return err;
            })
}

export function getCommentsTweets(idTweet) {
    const url = `${API_HOST}/tweet/comments?id=${idTweet}`
    const params = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`
        }
    }
    
    return fetch(url, params)
            .then(response => {
                return response.json()
            })
            .catch(err => {
                return err;
            })
}

/*--------------QUOTE-------------------- */
export function addQuoteTweet(idTweet, message) {
    const url = `${API_HOST}/tweet/quotetweet`;
    const data = {
        message,
        twitter_retweet: idTweet
    }

    const params = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`
        },
        body: JSON.stringify(data)
    }

    return fetch(url, params)
            .then(response => {
                if (response.status === 201) {
                    return { "status": true }
                } else {
                    return { "status": false }
                }
            })
            .catch(err => {
                return err;
            })
}

export function getQuoteTweets(idTweet) {
    const url = `${API_HOST}/tweet/quotetweets?id=${idTweet}`
    const params = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`
        }
    }
    
    return fetch(url, params)
            .then(response => {
                return response.json()
            })
            .catch(err => {
                return err;
            })
}

/*------------- RETWEET--------- */
export function addRetweet(idTweet) {
    const url = `${API_HOST}/tweet/retweet`;
    const data = {
        tweet: idTweet
    }
    const params = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`
        },
        body: JSON.stringify(data)
    }

    return fetch(url, params)
            .then(response => {
                if (response.status === 201) {
                    return { "status": true}
                } else {
                    return { "status": false}
                }
            })
            .catch(err => {
                return err;
            })
}

export function getRetweets(idTweet) {
    const url = `${API_HOST}/tweet/retweets?id=${idTweet}`
    const params = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`
        }
    }
    
    return fetch(url, params)
            .then(response => {
                return response.json()
            })
            .catch(err => {
                return err;
            })
}