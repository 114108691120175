import classNames from 'classnames';
import React, { useState } from 'react'
import { Form, Modal, Button } from 'react-bootstrap'
import { toast } from 'react-toastify';
import { addTweetApi } from '../../../api/tweet';
import { Close, Emoticon } from '../../../utils/icon';
import "./tweetModal.scss";
import Picker from "emoji-picker-react";


export default function TweetModal(props) {
    const { show, setShow } = props;
    const [message, setMessage] = useState("")
    const [showPicker, setShowPicker] = useState(false)
    const maxLength = 280;

    const onSubmit = (e) => {
        e.preventDefault()
        addTweetApi(message)
            .then(response => {
                if (response?.code >= 200 && response?.code <= 300) {
                    toast.success(response.message)
                    setShow(false);
                    window.location.reload()
                }
            })
            .catch(() => {
                toast.warning("Error al enviar el tweet")
            })
    }

    const addEmoji = (event, emojiObject) => {
        const updateMessage = `${message}${emojiObject.emoji}`
        console.log(updateMessage)
        setMessage(updateMessage)
        document.getElementById("message_tweet").value = updateMessage
    }

    return (
        <Modal 
            className="tweet-modal"
            show={show}
            onHide={() => setShow(false)}
            centered
            size="lg">
            <Modal.Header>
                <Modal.Title>
                    <Close onClick={() => setShow(false)}></Close>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={onSubmit}>
                    <Form.Control
                        as="textarea"
                        rows="10"
                        placeholder="¿Que estás pensando?"
                        id="message_tweet"
                        onChange={e => setMessage(e.target.value)}>
                    </Form.Control>
                    <span className={classNames("count", {error: message.length > maxLength})}>
                        {message.length}
                    </span>
                    <Emoticon onClick={() => setShowPicker(!showPicker)}></Emoticon>
                    {showPicker && <Picker onEmojiClick={addEmoji}></Picker>}
                    <Button 
                        type="submit"
                        disabled={message.length > maxLength || message.length < 1}> 
                        Twittoar
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    )
}
