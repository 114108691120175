import React from 'react'
import { Modal } from 'react-bootstrap';
import { Close } from '../../../utils/icon';
import ListUser from '../../listUser/listUser';
import "./usersModal.scss"

export default function UsersModal(props) {
    const { show, setShow, users, title } = props;
    return (
        <Modal 
            className="users-modal"
            show={show}
            onHide={() => setShow(false)}
            centered
            size="lg">
            <Modal.Header>
                <Modal.Title>
                    <Close onClick={() => setShow(false)}></Close> { title }
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ListUser users={users}></ListUser>
            </Modal.Body>
        </Modal>
    
    )
}
