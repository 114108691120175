import { API_HOST } from "../utils/constant";
import { getToken } from "./auth";

export function checkFollowApi(idUser) {
    const url = `${API_HOST}/relation?id=${idUser}`;
    const params = {
        headers: {
            Authorization: `Bearer ${getToken()}`
        }
    }

    return fetch(url, params)
            .then(response => {
                return response.json()
            })
            .catch(err => {
                return err;
            })
}

export function followUserApi(idUser) {
    const url = `${API_HOST}/relation?id=${idUser}`;
    const params = {
        method: "POST",
        headers: {
            Authorization: `Bearer ${getToken()}`
        }
    }
    return fetch(url, params)
    .then(response => {
        return response.json()
    })
    .catch(err => {
        return err;
    })
}

export function unfollowUserApi(idUser) {
    const url = `${API_HOST}/relation?id=${idUser}`;
    const params = {
        method: "DELETE",
        headers: {
            Authorization: `Bearer ${getToken()}`
        }
    }
    return fetch(url, params)
    .then(response => {
        return response.json()
    })
    .catch(err => {
        return err;
    })
}

export function getFollowApi(paramsUrl) {
    const url = `${API_HOST}/list-user?${paramsUrl}`;
    const params = {
        headers: {
            Authorization: `Bearer ${getToken()}`
        }
    }

    return fetch(url, params)
            .then(response => {
                return response.json()
            })
            .catch(error => {
                return error;
            })
}

export function getFollowersApi(id) {
    const url = `${API_HOST}/followers?id=${id}`;
    const params = {
        headers: {
            Authorization: `Bearer ${getToken()}`
        }
    }
    return fetch(url, params)
        .then(response => {
            return response.json()
        })
        .catch(error => {
            return error;
        })
}

export function getFollowingApi(id) {
    const url = `${API_HOST}/following?id=${id}`;
    const params = {
        headers: {
            Authorization: `Bearer ${getToken()}`
        }
    }
    return fetch(url, params)
        .then(response => {
            return response.json()
        })
        .catch(error => {
            return error;
        })
}