import React from 'react'
import { Modal } from 'react-bootstrap';
import { Close } from '../../../utils/icon';
import "./quoteTweetsModal.scss"
import ListQuoteTweets from '../../listQuoteTweets/listQuoteTweets';

export default function QuoteTweetsModal(props) {
    const { show, setShow, quoteTweets, title, tweet, userInfo } = props;
    console.log(quoteTweets)
    console.log(tweet)
    return (
        <Modal
            className="quote-tweets-modal"
            show={show}
            onHide={() => setShow(false)}
            centered
            size="lg">
            <Modal.Header>
                <Modal.Title>
                    <Close onClick={() => setShow(false)}></Close> { title }
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ListQuoteTweets userInfo={userInfo} tweet={tweet} quoteTweets={quoteTweets}></ListQuoteTweets>
            </Modal.Body>
        </Modal>
    )
}
